import React, {useEffect, useState} from 'react';
import APIservices from "../../services/APIservices";
import {Button, Form} from "react-bootstrap";
import AlertPopup from "../common/AlertPopup";
import {useHistory} from "react-router";
import Loader from "../common/Loader";

export default function EditMeter(props) {

    const history = useHistory();

    const [state, setState] = useState({
        formData: {
            uuid: props.match.params.uuid,
            name: '',
            meter_no: '',
            type: '',
            account_id: '',
            code: '',
        },
        meterTypes: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    let close = (e) => {
        let url = '/meters'
        history.push(url);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        APIservices.submitMeter(state.formData).then((response) => {
            let showAlert = state.showAlert;
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState({isLoading: false, showAlert: showAlert});
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
                alterState('showAlert', showAlert)
            }
        });
    }

    useEffect(() => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getMeterTypes(),
            APIservices.getMeter(state.formData.uuid)
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                let showAlert = state.showAlert;
                let formData = state.formData;
                const meterTypes = data[0].value;
                const meter = data[1].value;

                if(meter.data.status === true) {
                    formData.name = meter.data.data.name;
                    formData.meter_no = meter.data.data.meter_no;
                    formData.type = meter.data.data.type.uuid;
                    formData.account_id = meter.data.data.account_id;
                    formData.code = meter.data.data.code;
                } else {
                    showAlert['isError'] = true;
                    showAlert['message'] = meter.data.data.message + " (" + meter.data.data.code + ")";
                }

                alterState({
                    meterTypes: meterTypes.data.status === true ? meterTypes.data.data : [],
                    isLoading: false,
                    showAlert: showAlert,
                    formData: formData
                });
            })
            .catch((err) => {
                console.error(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <AlertPopup
                    onConfirm={close}
                    alterAlertState={alterAlertState}
                    show={state.showAlert.show}
                    isError={state.showAlert.isError}
                    message={state.showAlert.message}
                />

                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        name='name'
                        type="text"
                        placeholder="Meter Name"
                        value={state.formData.name}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="type">
                    <Form.Label>Meter Type</Form.Label>
                    <Form.Control
                        name='type'
                        as="select"
                        value={state.formData.type.length === 0 ? 'Select Meter Type' : state.formData.type}
                        onChange={handleUserInput}
                    >
                        <option>Select</option>
                        {state.meterTypes !== undefined && state.meterTypes.map((meter_type) => {
                            return (<option key={meter_type.uuid}
                                            value={meter_type.uuid}>{meter_type.name}</option>)
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="meter_no">
                    <Form.Label>Meter No</Form.Label>
                    <Form.Control
                        name='meter_no'
                        type="text"
                        placeholder="Meter No"
                        value={state.formData.meter_no}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="account_id">
                    <Form.Label>Account ID</Form.Label>
                    <Form.Control
                        name='account_id'
                        type="text"
                        placeholder="Account ID"
                        value={state.formData.account_id}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="code">
                    <Form.Label>Code</Form.Label>
                    <Form.Control
                        name='code'
                        type="text"
                        placeholder="Code"
                        value={state.formData.code || ''}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Button onClick={submitForm} variant="primary">Update</Button>
            </div>
        );
    }
}