import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import MeterReadingInfo from "./MeterReadingInfo";
import LinkedMeter from "../submeters/LinkedMeter";

export default function ViewMeterReading(props) {

    const [state, setState] = useState(
        {
            isLoading: true,
            data: {}
        });

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    useEffect(() => {
        APIservices.getMeterReading(props.match.params.uuid).then((response) => {
            if (response.data.status === true) {
                alterState({data: response.data.data, isLoading: false});
            } else {
                alert("Error : " + response.data.data.message + " (" + response.data.data.code + ")")
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div className="view-entity-details">
                <div className="h1 entity-margin-1">Meter Reading &nbsp;
                    <small>{state.data.name}</small>
                </div>
                <MeterReadingInfo data={state.data}/>
                <LinkedMeter meter={state.data.meter}/>
            </div>
        );
    }
}