import API from "./API";

class APIservices {

    submitMeter = async (formData) => {
        return await API.postData('/meters', formData);
    }

    submitSubMeter = async (formData) => {
        return await API.postData('/sub-meters', formData);
    }

    getMeters = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/meters');
        } else {
            return await API.getData('/meters', {name: name});
        }
    }

    getMeterNotifications = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/meter-notifications');
        } else {
            return await API.getData('/meter-notifications', {name: name});
        }
    }

    getSubMeterNotifications = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/sub-meter-notifications');
        } else {
            return await API.getData('/sub-meter-notifications', {name: name});
        }
    }

    getMeter = async (uuid) => {
        return await API.getData('/meters/' + uuid);
    }

    getMetersSubMeter = async (uuid) => {
        return await API.getData('/meters/' + uuid + '/sub-meters');
    }

    getSubMeter = async (uuid) => {
        return await API.getData('/sub-meters/' + uuid);
    }

    getSubMeters = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/sub-meters');
        } else {
            return await API.getData('/sub-meters', {name: name});
        }
    }

    getMeterTypes = async () => {
        return await API.getData('/meter-types');
    }

    getMeterReadings = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/meter-readings');
        } else {
            return await API.getData('/meter-readings', {name: name});
        }
    }

    getReadingsForMeter = async (uuid) => {
        return await API.getData('/meters/' + uuid + '/meter-readings');
    }

    getReadingsForSubMeter = async (uuid) => {
        return await API.getData('/sub-meters/' + uuid + '/sub-meter-readings');
    }

    getMeterReading = async (uuid) => {
        return await API.getData('/meter-readings/' + uuid);
    }

    getMeterNotification = async (uuid) => {
        return await API.getData('/meter-notifications/' + uuid);
    }

    deleteMeterNotification = async (uuid) => {
        return await API.deleteData('/meter-notifications/' + uuid);
    }

    getSubMeterNotification = async (uuid) => {
        return await API.getData('/sub-meter-notifications/' + uuid);
    }

    deleteSubMeterNotification = async (uuid) => {
        return await API.deleteData('/sub-meter-notifications/' + uuid);
    }

    getSubMeterReading = async (uuid) => {
        return await API.getData('/sub-meter-readings/' + uuid);
    }

    submitMeterReading = async (formData) => {
        return await API.postData('/meter-readings', formData);
    }

    submitMeterNotification = async (formData) => {
        return await API.postData('/meter-notifications', formData);
    }

    submitSubMeterNotification = async (formData) => {
        return await API.postData('/sub-meter-notifications', formData);
    }

    getSubMeterReadings = async (name = '') => {
        if (name.length === 0) {
            return await API.getData('/sub-meter-readings');
        } else {
            return await API.getData('/sub-meter-readings', {name: name});
        }
    }

    submitSubMeterReading = async (formData) => {
        return await API.postData('/sub-meter-readings', formData);
    }

    login = async (formData) => {
        return await API.postData('/login', formData);
    }

    logout = async () => {
        return await API.postData('/logout', {});
    }
}

export default new APIservices();