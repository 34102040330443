import React from "react";
import SweetAlert from 'sweetalert2-react';

export default function AlertPopup(props) {
    let title = props.isError ? 'Failed' : 'Successful';
    return (<SweetAlert
        show={props.show}
        title={title}
        text={props.message}
        onConfirm={props.onConfirm}
    />);
}