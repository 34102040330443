import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import AlertPopup from "../common/AlertPopup";
import {useHistory} from "react-router";


export default function EditMeterNotification(props) {

    const history = useHistory();

    const [state, setState] = useState({
        formData: {
            uuid: props.match.params.uuid,
            meter_id: '',
            name: '',
            email: '',
            mobile: ''
        },
        meters: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    let close = (e) => {
        let url = '/meter-notifications'
        history.push(url);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let handleUserSelectInput = e => {
        e.preventDefault();
        const {name, value,} = e.target;
        let formData = state.formData;
        formData[name] = value;
        alterState('formData', formData)
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        APIservices.submitMeterNotification(state.formData).then((response) => {
            let showAlert = state.showAlert;
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState('isLoading', false);
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
            }
            alterState('showAlert', showAlert)
        });
    }

    useEffect(() => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getMeters(),
            APIservices.getMeterNotification(state.formData.uuid)
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                let showAlert = state.showAlert;
                let formData = state.formData;
                const meters = data[0].value;
                const meterNotification = data[1].value;
                if (meterNotification.data.status === true) {
                    formData.meter_id = meterNotification.data.data.meter.uuid;
                    formData.name = meterNotification.data.data.name;
                    formData.email = meterNotification.data.data.email;
                    formData.mobile = meterNotification.data.data.mobile;
                } else {
                    showAlert['isError'] = true;
                    showAlert['message'] = meterNotification.data.data.message + " (" + meterNotification.data.data.code + ")";
                }
                alterState({
                    meters: meters.data.status === true ? meters.data.data : [],
                    isLoading: false,
                    showAlert: showAlert,
                    formData: formData
                });
            })
            .catch((err) => {
                console.error(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <AlertPopup
                    onConfirm={close}
                    alterAlertState={alterAlertState}
                    show={state.showAlert.show}
                    isError={state.showAlert.isError}
                    message={state.showAlert.message}
                />
                <Form.Group controlId="meter_id">
                    <Form.Label>Meter</Form.Label>
                    <Form.Control
                        name='meter_id'
                        as="select"
                        value={state.formData.meter_id.length === 0 ? 'Select Meter' : state.formData.meter_id}
                        onChange={handleUserSelectInput}
                    >
                        <option>Select</option>
                        {state.meters !== undefined && state.meters.map((meter) => {
                            return (<option key={meter.uuid}
                                            value={meter.uuid}>{meter.name}</option>)
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        name='name'
                        type="text"
                        placeholder="Name"
                        defaultValue={state.formData.name}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        name='email'
                        type="text"
                        placeholder="Email"
                        value={state.formData.email}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                        name='mobile'
                        type="text"
                        placeholder="mobile"
                        value={state.formData.mobile}
                        onChange={handleUserInput}
                    />
                </Form.Group>
                <Button onClick={submitForm} variant="primary">Add</Button>
            </div>
        );
    }
}