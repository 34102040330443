import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import MeterInfo from "./MeterInfo";
import LinkedSubMeter from "./LinkedSubMeter";
import {Col, Row} from "react-bootstrap";
import TableData from "../common/TableData";

export default function ViewMeter(props) {

    const [state, setState] = useState(
        {
            isLoading: true,
            data: {},
            tableData: [],
            columns: {
                fields: [
                    {
                        text: '#',
                        index: true,
                    },
                    {
                        dataField: 'uuid',
                        text: 'UUID',
                        hidden: true,
                    },
                    {
                        dataField: 'last_reading',
                        text: 'Last Reading',
                    },
                    {
                        dataField: 'current_reading',
                        text: 'Current Reading',
                    },
                    {
                        dataField: 'amount',
                        text: 'Amount',
                    },
                    {
                        dataField: 'date_from',
                        text: 'Date From',
                    },
                    {
                        dataField: 'date_to',
                        text: 'Date To',
                    },
                ]
            },
        });

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    useEffect(() => {
        const promiseArray = [
            APIservices.getMeter(props.match.params.uuid),
            APIservices.getReadingsForMeter(props.match.params.uuid)
        ];

        Promise.allSettled(promiseArray)
            .then((data) => {
                const meters = data[0].value;
                const metersReadings = data[1].value;
                if(meters.data.status !== true) {
                    alert("Error : " + meters.data.data.message + " (" + meters.data.data.code + ")")
                } else {
                    alterState({data: meters.data.data, isLoading: false, tableData: metersReadings.data.status === true ? metersReadings.data.data : []});
                }
            })
            .catch((err) => {
                console.error(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div className="view-entity-details">
                <div className="h1 entity-margin-1">Meter &nbsp;
                    <small>{state.data.name}</small>
                </div>
                <MeterInfo data={state.data}/>
                <LinkedSubMeter subMeters={state.data.sub_meters}/>
                <div className="h4 entity-margin-1">Meter Reading</div>
                <Row>
                    <Col>
                        <TableData headers={state.columns} rows={state.tableData}/>
                    </Col>
                </Row>
            </div>
        );
    }
}