import React from "react";
import {CardDeck, Row} from "react-bootstrap";
import SubMeterCard from "./SubMeterCard";

export default function LinkedSubMeter(props) {
    return (
        <div>
            <div className="h4 entity-margin-1">Linked Sub Meters</div>

            <Row>
                <CardDeck>
                    {props.subMeters.map((subMeter, subIndex) => {
                        return (<SubMeterCard key={subMeter.uuid} subMeter={subMeter}/>)
                    })}
                </CardDeck>
            </Row>
        </div>
    );
}