import React, {useEffect} from 'react';
import './App.css';
import SideBar from "./components/common/SideBar";
import TopNavigation from "./components/common/TopNavigation";
import {PrivateRoute, PublicRoute} from "./services/Route";
import {Switch} from "react-router-dom";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Home from "./components/home/Home";
import Meters from "./components/meters/Meters";
import SubMeters from "./components/submeters/SubMeters";
import ViewMeter from "./components/meters/ViewMeter";
import ViewSubMeter from "./components/submeters/ViewSubMeter";
import EditMeter from "./components/meters/EditMeter";
import EditSubMeter from "./components/submeters/EditSubMeter";
import MeterReadings from "./components/meterreading/MeterReadings";
import SubMeterReadings from "./components/submeterreading/SubMeterReadings";
import ViewMeterReading from "./components/meterreading/ViewMeterReading";
import ViewSubMeterReading from "./components/submeterreading/ViewSubMeterReading";
import EditMeterReading from "./components/meterreading/EditMeterReading";
import EditSubMeterReading from "./components/submeterreading/EditSubMeterReading";
import MeterNotification from "./components/meternotification/MeterNotification";
import ViewMeterNotification from "./components/meternotification/ViewMeterNotification";
import SubMeterNotification from "./components/submeternotification/SubMeterNotification";
import ViewSubMeterNotification from "./components/submeternotification/ViewSubMeterNotification";
import EditMeterNotification from "./components/meternotification/EditMeterNotification";
import EditSubMeterNotification from "./components/submeternotification/EditSubMeterNotification";


function App(props) {
    useEffect(() => {

    }, []);
    return (
        <div id="MainApp">
            <SideBar pageWrapId={"page-wrap"} outerContainerId={"MainApp"}/>
            <div id="page-wrap">
                <TopNavigation/>
                <div className="container">
                    <React.StrictMode>
                        <Switch>

                            <PublicRoute
                                exact
                                path="/login"
                                component={Login}
                            />

                            <PrivateRoute
                                exact
                                path="/meter/:uuid/edit"
                                component={EditMeter}
                            />

                            <PrivateRoute
                                exact
                                path="/meter/:uuid"
                                component={ViewMeter}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter/:uuid/edit"
                                component={EditSubMeter}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter/:uuid"
                                component={ViewSubMeter}
                            />

                            <PrivateRoute
                                exact
                                path="/"
                                component={Home}
                            />

                            <PrivateRoute
                                exact
                                path="/meters"
                                component={Meters}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meters"
                                component={SubMeters}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-notifications"
                                component={MeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-notification/:uuid/edit"
                                component={EditMeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-notification/:uuid"
                                component={ViewMeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-notifications"
                                component={SubMeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-notification/:uuid"
                                component={ViewSubMeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-notification/:uuid/edit"
                                component={EditSubMeterNotification}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-reading/:uuid"
                                component={ViewMeterReading}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-reading/:uuid/edit"
                                component={EditMeterReading}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-reading/:uuid"
                                component={ViewSubMeterReading}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-reading/:uuid/edit"
                                component={EditSubMeterReading}
                            />

                            <PrivateRoute
                                exact
                                path="/meter-readings"
                                component={MeterReadings}
                            />

                            <PrivateRoute
                                exact
                                path="/sub-meter-readings"
                                component={SubMeterReadings}
                            />

                            <PrivateRoute
                                exact
                                path="/logout"
                                component={Logout}
                            />
                        </Switch>
                    </React.StrictMode>
                </div>
            </div>
        </div>
    );
}

export default App;
