import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import SubMeterInfo from "./SubMeterInfo";
import LinkedMeter from "./LinkedMeter";
import {Col, Row} from "react-bootstrap";
import TableData from "../common/TableData";

export default function ViewSubMeter(props) {

    const [state, setState] = useState(
        {
            isLoading: true,
            data: {},
            tableData: [],
            columns: {
                fields: [
                    {
                        text: '#',
                        index: true,
                    },
                    {
                        dataField: 'uuid',
                        text: 'UUID',
                        hidden: true,
                    },
                    {
                        dataField: 'last_reading',
                        text: 'Last Reading',
                    },
                    {
                        dataField: 'current_reading',
                        text: 'Current Reading',
                    },
                    {
                        dataField: 'amount',
                        text: 'Amount',
                    },
                    {
                        dataField: 'date_from',
                        text: 'Date From',
                    },
                    {
                        dataField: 'date_to',
                        text: 'Date To',
                    },
                ]
            },
        });

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    useEffect(() => {
        const promiseArray = [
            APIservices.getSubMeter(props.match.params.uuid),
            APIservices.getReadingsForSubMeter(props.match.params.uuid)
        ];

        Promise.allSettled(promiseArray)
            .then((data) => {
                const subMeters = data[0].value;
                const subMetersReadings = data[1].value;

                if(subMeters.data.status !== true) {
                    alert("Error : " + subMeters.data.data.message + " (" + subMeters.data.data.code + ")")
                } else {
                    alterState({data: subMeters.data.data, isLoading: false, tableData: subMetersReadings.data.status === true ? subMetersReadings.data.data : []});
                }
            })
            .catch((err) => {
                console.error(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div className="view-entity-details">
                <div className="h1 entity-margin-1">Sub Meter &nbsp;
                    <small>{state.data.name}</small>
                </div>
                <SubMeterInfo data={state.data}/>
                <LinkedMeter meter={state.data.meter}/>
                <div className="h4 entity-margin-1">Sub Meter Reading</div>
                <Row>
                    <Col>
                        <TableData headers={state.columns} rows={state.tableData}/>
                    </Col>
                </Row>
            </div>
        );
    }
}