import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import "./CSS/MeterReadingInfo.css"

export default function MeterReadingInfo(props) {
    return (
        <div>
            <Row>
                <Col>
                    <Table className='MeterReadingInfo' borderless size="sm">
                        <tbody>
                        <tr>
                            <th>Type</th>
                            <td>{props.data.meter.type.name}</td>
                        </tr>
                        <tr>
                            <th>Meter Name</th>
                            <td>{props.data.meter.name}</td>
                        </tr>
                        <tr>
                            <th>Meter No</th>
                            <td>{props.data.meter.meter_no}</td>
                        </tr>
                        <tr>
                            <th>Account ID</th>
                            <td>{props.data.meter.account_id}</td>
                        </tr>
                        <tr>
                            <th>Code</th>
                            <td>{props.data.meter.code}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table className='MeterReadingInfo' borderless size="sm">
                        <tbody>
                        <tr>
                            <th>Last Reading</th>
                            <td>{props.data.last_reading}</td>
                        </tr>
                        <tr>
                            <th>Current Reading</th>
                            <td>{props.data.current_reading}</td>
                        </tr>
                        <tr>
                            <th>Amount</th>
                            <td>{props.data.amount}</td>
                        </tr>
                        <tr>
                            <th>Date From</th>
                            <td>{props.data.date_from}</td>
                        </tr>
                        <tr>
                            <th>Date To</th>
                            <td>{props.data.date_to}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
}