import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import "./CSS/SubMeterNotificationInfo.css"

export default function SubMeterNotificationInfo(props) {
    return (
        <Row>
            <Col>
                <Table className='SubMeterNotificationInfo' borderless size="sm">
                    <tbody>
                    <tr>
                        <th>Sub Meter</th>
                        <td>{props.data.sub_meter.name}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{props.data.name}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{props.data.email}</td>
                    </tr>
                    <tr>
                        <th>Mobile</th>
                        <td>{props.data.mobile}</td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}