import React, { useEffect, useState} from "react";
import {Button, Col, Form, Modal, Table} from "react-bootstrap";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import AlertPopup from "../common/AlertPopup";

export default function AddMeterReadingModel(props) {
    const [state, setState] = useState({
        formData: {
            uuid: null,
            meter_id: '',
            last_reading: '',
            current_reading: '',
            amount: '',
            date_from: '',
            date_to: ''
        },
        add_sub_meter: false,
        sub_meter_readings: [],
        meters: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let close = (e) => {
        closeModel();
    }

    let closeModel = () => {
        alterState({
            isLoading: false,
            formData: {
                uuid: null,
                meter_id: '',
                last_reading: '',
                current_reading: '',
                amount: '',
                date_from: '',
                date_to: '',
            },
            sub_meter_readings: [],
            showAlert: {
                show: false,
                isError: false,
                message: ''
            }
        });
        props.alterState({showAddMeterReadingModel: false, refreshMeterReadingList: true});
    }

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let handleUserSubInput = e => {
        e.preventDefault();
        let key = e.target.dataset.key;
        const {name, value} = e.target;
        let sub_meter_readings = state.sub_meter_readings;
        sub_meter_readings[key][name] = value
        alterState({sub_meter_readings:sub_meter_readings})
    }

    let handleUserCheckInput = e => {
        let checked = e.target.checked;
        alterState({add_sub_meter:checked})
    }

    let handleUserSelectInput = e => {
        e.preventDefault();
        const optionObj = e.target.options[e.target.selectedIndex];
        const {name, value,} = e.target;
        let formData = state.formData;
        formData[name] = value;
        formData['last_reading'] = optionObj.getAttribute('data-lastreading') || '';
        formData['date_from'] = optionObj.getAttribute('data-lastreadingdate') || '';
        alterState({formData: formData})
        APIservices.getMetersSubMeter(value).then((response) => {
            if (response.data.status === true) {
                // console.log(response.data.data);
                response.data.data.forEach((sub_meter, subIndex) => {
                    let sub_meter_readings = state.sub_meter_readings
                    sub_meter_readings.push({
                        sub_meter_id: sub_meter.uuid,
                        name: sub_meter.name,
                        account_id: sub_meter.account_id,
                        last_reading: sub_meter.latest_reading.current_reading,
                        date_from: sub_meter.latest_reading.date_to,
                        current_reading: '',
                        date_to: new Date().toISOString().slice(0, 10)
                    });
                    alterState({sub_meter_readings: sub_meter_readings})
                });
                // alterState({sub_meter_readings: sub_meter_readings})
            }
        });

    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        let formData = state.formData;
        if(state.add_sub_meter) {
            formData.sub_meter_readings = state.sub_meter_readings;
        }
        let showAlert = state.showAlert;
        APIservices.submitMeterReading(formData).then((response) => {
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState('isLoading', false);
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
            }
            alterState('showAlert', showAlert)
        }).catch(error => {
            showAlert['show'] = true;
            showAlert['isError'] = true;
            showAlert['message'] = "Save Failed !!";
            alterState('showAlert', showAlert)
        });
    }

    let initData = (e) => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getMeters()
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                const meters = data[0].value;
                let formData = state.formData;
                formData.date_to = new Date().toISOString().slice(0, 10);
                alterState({
                    formData: formData,
                    meters: meters.data.status === true ? meters.data.data : [],
                    isLoading: false
                });
            })
            .catch((err) => {
                console.error(err)
            });
    }

    return (
        <div>
            <AlertPopup
                onConfirm={close}
                alterAlertState={alterAlertState}
                show={state.showAlert.show}
                isError={state.showAlert.isError}
                message={state.showAlert.message}
            />
            <Modal
                onShow={initData}
                size="xl"
                show={props.show}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Add Meter Reading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.isLoading ? <Loader/> :
                        <div>

                            <Form.Group controlId="meter_id">
                                <Form.Label>Meter</Form.Label>
                                <Form.Control
                                    required
                                    name='meter_id'
                                    as="select"
                                    value={state.formData.meter_id.length === 0 ? 'Select Meter' : state.formData.meter_id}
                                    onChange={handleUserSelectInput}
                                >
                                    <option>Select</option>
                                    {state.meters !== undefined && state.meters.map((meter) => {
                                        return (<option key={meter.uuid}
                                                        data-lastreading={meter.latest_reading.current_reading}
                                                        data-lastreadingdate={meter.latest_reading.date_to}
                                                        value={meter.uuid}>{meter.name}</option>)
                                    })}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="last_reading">
                                <Form.Label>Last Reading</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    disabled
                                    name='last_reading'
                                    type="text"
                                    placeholder="Meter Last Reading"
                                    defaultValue={state.formData.last_reading}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="current_reading">
                                <Form.Label>Current Reading</Form.Label>
                                <Form.Control
                                    required
                                    min='0'
                                    name='current_reading'
                                    type="number"
                                    placeholder="Meter Current Reading"
                                    value={state.formData.current_reading}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col} controlId="date_from">
                                    <Form.Label>Date From</Form.Label>
                                    <Form.Control
                                        disabled
                                        readOnly={true}
                                        name='date_from'
                                        type="date"
                                        placeholder="Meter Reading From Date"
                                        defaultValue={state.formData.date_from}
                                        onChange={handleUserInput}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="date_to">
                                    <Form.Label>Date To</Form.Label>
                                    <Form.Control
                                        required
                                        name='date_to'
                                        type="date"
                                        placeholder="Meter Reading To Date"
                                        value={state.formData.date_to}
                                        onChange={handleUserInput}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group controlId="account_id">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    required
                                    min='0'
                                    name='amount'
                                    type="number"
                                    placeholder="Amount"
                                    value={state.formData.amount}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="add_sub_meter">
                                <Form.Check
                                    type='checkbox'
                                    id='add_sub_meter'
                                    checked={state.add_sub_meter}
                                    onChange={handleUserCheckInput}
                                    label='Add Sub Meter Reading'
                                />
                            </Form.Group>

                            {state.add_sub_meter &&
                            <Table className='AddMeterReadingModel' borderless size="sm">
                                <tbody>
                                {state.sub_meter_readings.map((sub_meter_reading, subIndex) => {
                                    return (
                                        <tr key={subIndex}>
                                            <th>
                                                <Form.Group controlId={sub_meter_reading.sub_meter_id + "name"}>
                                                    <Form.Label> Name</Form.Label>
                                                    <Form.Control
                                                        data-key={subIndex}
                                                        readOnly={true}
                                                        disabled
                                                        name='name'
                                                        type="text"
                                                        placeholder="Sub Meter Name"
                                                        defaultValue={state.sub_meter_readings[subIndex].name}
                                                        onChange={handleUserSubInput}
                                                    />
                                                </Form.Group>
                                            </th>
                                            <th>
                                                <Form.Group controlId={sub_meter_reading.sub_meter_id + "account_id"}>
                                                    <Form.Label>Account ID</Form.Label>
                                                    <Form.Control
                                                        data-key={subIndex}
                                                        readOnly={true}
                                                        disabled
                                                        name='account_id'
                                                        type="text"
                                                        placeholder="Sub Meter Account ID"
                                                        defaultValue={state.sub_meter_readings[subIndex].account_id}
                                                        onChange={handleUserSubInput}
                                                    />
                                                </Form.Group>
                                            </th>
                                            <th>
                                                <Form.Group controlId={sub_meter_reading.sub_meter_id + "last_reading"}>
                                                    <Form.Label>Last Reading</Form.Label>
                                                    <Form.Control
                                                        data-key={subIndex}
                                                        readOnly={true}
                                                        disabled
                                                        name='last_reading'
                                                        type="text"
                                                        placeholder="Sub Meter Last Reading"
                                                        defaultValue={state.sub_meter_readings[subIndex].last_reading}
                                                        onChange={handleUserSubInput}
                                                    />
                                                </Form.Group>
                                            </th>
                                            <td>
                                                <Form.Group controlId={sub_meter_reading.sub_meter_id + "date_from"}>
                                                <Form.Label>Date From</Form.Label>
                                                <Form.Control
                                                    data-key={subIndex}
                                                    disabled
                                                    readOnly={true}
                                                    name='date_from'
                                                    type="date"
                                                    placeholder="Sub Meter Reading From Date"
                                                    defaultValue={state.sub_meter_readings[subIndex].date_from}
                                                    onChange={handleUserSubInput}
                                                />
                                            </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group controlId={sub_meter_reading.sub_meter_id + "current_reading"}>
                                                    <Form.Label>Current Reading</Form.Label>
                                                    <Form.Control
                                                        required
                                                        min='0'
                                                        data-key={subIndex}
                                                        name='current_reading'
                                                        type="number"
                                                        placeholder="Sub Meter Current Reading"
                                                        onChange={handleUserSubInput}
                                                    />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table> }

                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button onClick={submitForm} variant="primary">Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}