import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import "./CSS/MeterNotificationInfo.css"

export default function MeterNotificationInfo(props) {
    return (
            <Row>
                <Col>
                    <Table className='MeterNotificationInfo' borderless size="sm">
                        <tbody>
                        <tr>
                            <th>Meter</th>
                            <td>{props.data.meter.name}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{props.data.name}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{props.data.email}</td>
                        </tr>
                        <tr>
                            <th>Mobile</th>
                            <td>{props.data.mobile}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
    );
}