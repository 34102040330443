import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import {Button, Col, Row} from "react-bootstrap";
import TableData from "../common/TableData";
import AddMeterReadingModel from "./AddMeterReadingModel";
import {useHistory} from "react-router";

export default function MeterReadings(props) {

    const history = useHistory();

    let viewMeterReading = (row, operation) => {
        let url = '/meter-reading/' + row.uuid
        history.push(url);
    }

    let editMeterReading = (row, operation) => {
        let url = '/meter-reading/' + row.uuid + '/edit'
        history.push(url);
    }

    const [state, setState] = useState({
        tableData: [],
        columns: {
            fields: [
                {
                    dataField: 'uuid',
                    text: 'UUID',
                    hidden: true,
                },
                {
                    dataField: function (row) {
                        return row.meter.name;
                    },
                    text: 'Meter Name',
                },
                {
                    dataField: 'last_reading',
                    text: 'Last Reading',
                },
                {
                    dataField: 'current_reading',
                    text: 'Current Reading',
                },
                {
                    dataField: 'amount',
                    text: 'Amount',
                },
                {
                    dataField: 'date_from',
                    text: 'Date From',
                },
                {
                    dataField: 'date_to',
                    text: 'Date To',
                },
            ],
            operation: [
                {
                    icon: 'fa-eye',
                    text: 'View',
                    onClick: viewMeterReading
                },
                {
                    icon: 'fa-pencil',
                    text: 'Edit',
                    onClick: editMeterReading
                }
            ]
        },
        filters: {
            page: {
                size: 10,
                current: 1
            }
        },
        isLoading: true,
        showAddMeterReadingModel: false,
        refreshMeterReadingList: false
    });

    useEffect(() => {
        refreshMeterReadingList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let refreshMeterReadingList = () => {
        APIservices.getMeterReadings().then((response) => {
            if (response.data.status === true) {
                alterState({tableData: response.data.data, refreshMeterReadingList: false, isLoading: false});
            } else {
                alert("Error : " + response.data.data.message + " (" + response.data.data.code + ")");
                alterState({refreshMeterReadingList: false, isLoading: false});
            }
        });
    }

    if (state.refreshMeterReadingList) {
        refreshMeterReadingList();
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <AddMeterReadingModel alterState={alterState} show={state.showAddMeterReadingModel}/>
                <Row>
                    <Col>
                        <h1>Meter Reading</h1>
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            alterState('showAddMeterReadingModel', true)
                        }} style={{margin: "5px"}} variant="primary">Add Meter Reading</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TableData headers={state.columns} rows={state.tableData}/>
                    </Col>
                </Row>
            </div>
        );
    }
}