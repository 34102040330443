import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import AlertPopup from "../common/AlertPopup";

export default function AddSubMeterModel(props) {
    const [state, setState] = useState({
        formData: {
            uuid: null,
            name: '',
            meter_no: '',
            meter_id: '',
            account_id: '',
            code: '',
        },
        meters: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let close = (e) => {
        closeModel();
    }

    let closeModel = () => {
        alterState({
            preview: null,
            isLoading: false,
            formData: {
                uuid: null,
                name: '',
                meter_no: '',
                meter_id: '',
                account_id: '',
                code: '',
            },
            showAlert: {
                show: false,
                isError: false,
                message: ''
            }
        });
        props.alterState({showAddSubMeterModel: false, refreshSubMeterList: true});
    }

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
        // console.log(state.formData);
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        let showAlert = state.showAlert;
        APIservices.submitSubMeter(state.formData).then((response) => {
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState('isLoading', false);
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
            }
            alterState('showAlert', showAlert)
        }).catch(error => {
            showAlert['show'] = true;
            showAlert['isError'] = true;
            showAlert['message'] = "Save Failed !!";
            alterState('showAlert', showAlert)
        });
    }

    let initData = (e) => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getMeters('')
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                const meters = data[0].value;
                alterState({
                    meters: meters.data.status === true ? meters.data.data : [],
                    isLoading: false
                });
            })
            .catch((err) => {
                console.error(err)
            });
    }

    return (
        <div>
            <AlertPopup
                onConfirm={close}
                alterAlertState={alterAlertState}
                show={state.showAlert.show}
                isError={state.showAlert.isError}
                message={state.showAlert.message}
            />
            <Modal
                onShow={initData}
                size="lg"
                show={props.show}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Add Sub-Meter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.isLoading ? <Loader/> :
                        <div>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name='name'
                                    type="text"
                                    placeholder="Sub Meter Name"
                                    value={state.formData.name}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="meter_id">
                                <Form.Label>Master Meter</Form.Label>
                                <Form.Control
                                    name='meter_id'
                                    as="select"
                                    value={state.formData.meter_id.length === 0 ? 'Select Meter Type' : state.formData.meter_id}
                                    onChange={handleUserInput}
                                >
                                    <option>Select</option>
                                    {state.meters !== undefined && state.meters.map((meter) => {
                                        return (<option key={meter.uuid}
                                                        value={meter.uuid}>{meter.name}</option>)
                                    })}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="meter_no">
                                <Form.Label>Meter No</Form.Label>
                                <Form.Control
                                    name='meter_no'
                                    type="text"
                                    placeholder="Meter No"
                                    value={state.formData.meter_no}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="account_id">
                                <Form.Label>Account ID</Form.Label>
                                <Form.Control
                                    name='account_id'
                                    type="text"
                                    placeholder="Account ID"
                                    value={state.formData.account_id}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="code">
                                <Form.Label>Code</Form.Label>
                                <Form.Control
                                    name='code'
                                    type="text"
                                    placeholder="Code"
                                    value={state.formData.code}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button onClick={submitForm} variant="primary">Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}