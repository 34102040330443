import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import "./CSS/SubMeterInfo.css"

export default function SubMeterInfo(props) {
    return (
        <Row>
            <Col>
                <Table className='SubMeterInfo' borderless size="sm">
                    <tbody>
                    <tr>
                        <th>Type</th>
                        <td>{props.data.meter.type.name}</td>
                    </tr>
                    <tr>
                        <th>Meter No</th>
                        <td>{props.data.meter_no}</td>
                    </tr>
                    <tr>
                        <th>Account ID</th>
                        <td>{props.data.account_id}</td>
                    </tr>
                    <tr>
                        <th>Code</th>
                        <td>{props.data.code}</td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}