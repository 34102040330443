import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import {Button, Col, Row} from "react-bootstrap";
import TableData from "../common/TableData";
import AddSubMeterReadingModel from "./AddSubMeterReadingModel";
import {useHistory} from "react-router";

export default function SubMeterReadings(props) {

    const history = useHistory();

    let viewSubMeterReading = (row, operation) => {
        let url = '/sub-meter-reading/' + row.uuid
        history.push(url);
    }

    let editSubMeterReading = (row, operation) => {
        let url = '/sub-meter-reading/' + row.uuid + '/edit'
        history.push(url);
    }

    const [state, setState] = useState({
        tableData: [],
        columns: {
            fields: [
                {
                    dataField: 'uuid',
                    text: 'UUID',
                    hidden: true,
                },
                {
                    dataField: function (row) {
                        return row.sub_meter.name;
                    },
                    text: 'Sub Meter Name',
                },
                {
                    dataField: 'last_reading',
                    text: 'Last Reading',
                },
                {
                    dataField: 'current_reading',
                    text: 'Current Reading',
                },
                {
                    dataField: 'amount',
                    text: 'Amount',
                },
                {
                    dataField: 'date_from',
                    text: 'Date From',
                },
                {
                    dataField: 'date_to',
                    text: 'Date To',
                },
            ],
            operation: [
                {
                    icon: 'fa-eye',
                    text: 'View',
                    onClick: viewSubMeterReading
                },
                {
                    icon: 'fa-pencil',
                    text: 'Edit',
                    onClick: editSubMeterReading
                }
            ]
        },
        filters: {
            page: {
                size: 10,
                current: 1
            }
        },
        isLoading: true,
        showAddSubMeterReadingModel: false,
        refreshSubMeterReadingList: false
    });

    useEffect(() => {
        refreshSubMeterReadingList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let refreshSubMeterReadingList = () => {
        APIservices.getSubMeterReadings().then((response) => {
            if (response.data.status === true) {
                alterState({tableData: response.data.data, refreshSubMeterReadingList: false, isLoading: false});
            } else {
                alert("Error : " + response.data.data.message + " (" + response.data.data.code + ")");
                alterState({refreshSubMeterReadingList: false, isLoading: false});
            }
        });
    }

    if (state.refreshSubMeterReadingList) {
        refreshSubMeterReadingList();
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <AddSubMeterReadingModel alterState={alterState} show={state.showAddSubMeterReadingModel}/>
                <Row>
                    <Col>
                        <h1>Sub Meter Reading</h1>
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            alterState('showAddSubMeterReadingModel', true)
                        }} style={{margin: "5px"}} variant="primary">Add Sub Meter Reading</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TableData headers={state.columns} rows={state.tableData}/>
                    </Col>
                </Row>
            </div>
        );
    }
}