import React from "react";
import {Card, Col} from "react-bootstrap";
import {useHistory} from "react-router";

export default function MeterCard(props) {

    const history = useHistory();

    let viewEntity = (event, uuid) => {
        let url = '/meter/' + uuid
        history.push(url);
    }

    return (
        <Col onClick={(event) => viewEntity(event, props.meter.uuid)} xs={6} md={4}>
            <Card style={{width: '18rem'}}>
                <div className="card-img-block">
                    <Card.Img variant="top"
                    />
                </div>
                <Card.Body className="pt-0">
                    <Card.Title>{props.meter.name}</Card.Title>
                    <Card.Text>
                        Meter Type : {props.meter.type.name}
                        {/*<span>Documents Count : {props.documents}</span>*/}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );

}