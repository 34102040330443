import React from "react";
import {CardDeck, Row} from "react-bootstrap";
import MeterCard from "./MeterCard";

export default function LinkedMeter(props) {
    return (
        <div>
            <div className="h4 entity-margin-1">Linked Meters</div>
            <Row>
                <CardDeck>
                    <MeterCard key={props.meter.uuid} meter={props.meter}/>
                </CardDeck>
            </Row>
        </div>
    );
}