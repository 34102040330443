import React from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function MeterCard(props) {
    return (<Card border="light" style={{width: '18rem'}}>
        <Card.Header>{props.meter.name}</Card.Header>
        <Card.Body>
            <Card.Title>
                <Link to={"/meter/" + props.meter.uuid}>
                    {props.meter.name}
                </Link>
            </Card.Title>
            <Card.Text>
                    Meter No : {props.meter.meter_no} <br/>
                    Account Id : {props.meter.account_id} <br/>
                    Code : {props.meter.code} <br/>
            </Card.Text>
        </Card.Body>
    </Card>);
}
