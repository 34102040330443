import React from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function SubMeterCard(props) {
    return (<Card border="light" style={{width: '18rem'}}>
        <Card.Header>{props.subMeter.name}</Card.Header>
        <Card.Body>
            <Card.Title>
                <Link to={"/sub-meter/" + props.subMeter.uuid}>
                    {props.subMeter.name}
                </Link>
            </Card.Title>
            <Card.Text>
                    Meter No : {props.subMeter.meter_no} <br/>
                    Account Id : {props.subMeter.account_id} <br/>
                    Code : {props.subMeter.code} <br/>
            </Card.Text>
        </Card.Body>
    </Card>);
}
