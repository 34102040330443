import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal} from "react-bootstrap";
import Loader from "../common/Loader";
import APIservices from "../../services/APIservices";
import AlertPopup from "../common/AlertPopup";

export default function AddSubMeterReadingModel(props) {
    const [state, setState] = useState({
        formData: {
            uuid: null,
            sub_meter_id: '',
            last_reading: '',
            current_reading: '',
            amount: '',
            date_from: '',
            date_to: '',
        },
        sub_meters: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let close = (e) => {
        closeModel();
    }

    let closeModel = () => {
        alterState({
            isLoading: false,
            formData: {
                uuid: null,
                sub_meter_id: '',
                last_reading: '',
                current_reading: '',
                amount: '',
                date_from: '',
                date_to: '',
            },
            showAlert: {
                show: false,
                isError: false,
                message: ''
            }
        });
        props.alterState({showAddSubMeterReadingModel: false, refreshSubMeterReadingList: true});
    }

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let handleUserSelectInput = e => {
        e.preventDefault();
        const optionObj = e.target.options[e.target.selectedIndex];
        const {name, value,} = e.target;
        let formData = state.formData;
        formData[name] = value;
        formData['last_reading'] = optionObj.getAttribute('data-lastreading') || '';
        formData['date_from'] = optionObj.getAttribute('data-lastreadingdate') || '';
        alterState('formData', formData)
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        let showAlert = state.showAlert;
        APIservices.submitSubMeterReading(state.formData).then((response) => {
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState('isLoading', false);
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
            }
            alterState('showAlert', showAlert)
        }).catch(error => {
            showAlert['show'] = true;
            showAlert['isError'] = true;
            showAlert['message'] = "Save Failed !!";
            alterState('showAlert', showAlert)
        });
    }

    let initData = (e) => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getSubMeters()
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                const sub_meters = data[0].value;
                let formData = state.formData;
                formData.date_to = new Date().toISOString().slice(0, 10);
                alterState({
                    formData:formData,
                    sub_meters: sub_meters.data.status === true ? sub_meters.data.data : [],
                    isLoading: false
                });
            })
            .catch((err) => {
                console.error(err)
            });
    }

    return (
        <div>
            <AlertPopup
                onConfirm={close}
                alterAlertState={alterAlertState}
                show={state.showAlert.show}
                isError={state.showAlert.isError}
                message={state.showAlert.message}
            />
            <Modal
                onShow={initData}
                size="lg"
                show={props.show}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Add Sub Meter Reading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.isLoading ? <Loader/> :
                        <div>

                            <Form.Group controlId="meter_id">
                                <Form.Label>Sub Meter</Form.Label>
                                <Form.Control
                                    name='sub_meter_id'
                                    as="select"
                                    value={state.formData.sub_meter_id.length === 0 ? 'Select Meter' : state.formData.sub_meter_id}
                                    onChange={handleUserSelectInput}
                                >
                                    <option>Select</option>
                                    {state.sub_meters !== undefined && state.sub_meters.map((sub_meter) => {
                                        return (<option key={sub_meter.uuid}
                                                        data-lastreading={sub_meter.latest_reading.current_reading}
                                                        data-lastreadingdate={sub_meter.latest_reading.date_to}
                                                        value={sub_meter.uuid}>{sub_meter.name}</option>)
                                    })}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="last_reading">
                                <Form.Label>Last Reading</Form.Label>
                                <Form.Control
                                    readOnly={true}
                                    disabled
                                    name='last_reading'
                                    type="text"
                                    placeholder="Sub Meter Last Reading"
                                    defaultValue={state.formData.last_reading}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="current_reading">
                                <Form.Label>Current Reading</Form.Label>
                                <Form.Control
                                    name='current_reading'
                                    type="text"
                                    placeholder="Sub Meter Current Reading"
                                    value={state.formData.current_reading}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col} controlId="date_from">
                                    <Form.Label>Date From</Form.Label>
                                    <Form.Control
                                        disabled
                                        readOnly={true}
                                        name='date_from'
                                        type="date"
                                        placeholder="Sub Meter Reading From Date"
                                        defaultValue={state.formData.date_from}
                                        onChange={handleUserInput}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="date_to">
                                    <Form.Label>Date To</Form.Label>
                                    <Form.Control
                                        name='date_to'
                                        type="date"
                                        placeholder="Meter Reading To Date"
                                        value={state.formData.date_to}
                                        onChange={handleUserInput}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group controlId="account_id">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    name='amount'
                                    type="text"
                                    placeholder="Amount"
                                    value={state.formData.amount}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button onClick={submitForm} variant="primary">Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}