import React, {useEffect, useState} from "react";
import APIservices from "../../services/APIservices";
import Loader from "../common/Loader";
import {Button, CardDeck, Col, Form, FormControl, Row} from "react-bootstrap";
import MeterCard from "../meters/MeterCard";


export default function Home(props) {
    const [state, setState] = useState({
        formData: {name: ''},
        meters: [],
        isLoading: true,
        refreshMeterList: false
    });

    let refreshMeterList = () => {
        APIservices.getMeters(state.formData.name).then((response) => {
            // console.log(response.data);
            if (response.data.status === true) {
                alterState({meters: response.data.data, refreshMeterList: false, isLoading: false});
            } else {
                alert("Error : " + response.data.data.message + " (" + response.data.data.code + ")");
                alterState({refreshMeterList: false, isLoading: false});
                // console.log(response.data);
            }
        });
    }

    useEffect(() => {
        refreshMeterList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state.refreshMeterList) {
        refreshMeterList();
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    const chunk = (arr, size) =>
        Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <Row style={{paddingBottom: "30px"}}>
                    <Col>
                        <div className='pull-right'>
                            <Form onClick={
                                () => {
                                    refreshMeterList()
                                }
                            } inline className="float-left">
                                <FormControl
                                    name='name'
                                    type="text"
                                    placeholder="Search"
                                    className="mr-sm-2"
                                    value={state.formData.name}
                                    onChange={handleUserInput}
                                />
                                <Button variant="outline-success">Search</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <div style={{paddingTop: "30px"}}>
                    {chunk(state.meters, 3).map((meters, index) => {
                        return (<Row key={index}>
                            {meters.map((meter, subIndex) => {
                                return (<CardDeck key={index + subIndex}>
                                    <MeterCard meter={meter}/>
                                </CardDeck>)
                            })} </Row>);
                    })}
                </div>
            </div>
        );
    }
}
