import React, {useEffect, useState} from 'react';
import APIservices from "../../services/APIservices";
import {Button, Col, Form} from "react-bootstrap";
import AlertPopup from "../common/AlertPopup";
import {useHistory} from "react-router";
import Loader from "../common/Loader";

export default function EditSubMeterReading(props) {

    const history = useHistory();

    const [state, setState] = useState({
        formData: {
            uuid: props.match.params.uuid,
            sub_meter_id: '',
            last_reading: '',
            current_reading: '',
            amount: '',
            date_from: '',
            date_to: '',
        },
        sub_meters: [],
        isLoading: false,
        showAlert: {
            show: false,
            isError: false,
            message: ''
        }
    });

    let close = (e) => {
        let url = '/sub-meter-readings'
        history.push(url);
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let alterAlertState = (key, value) => {
        let showAlert = state.showAlert;
        showAlert[key] = value;
        alterState('showAlert', showAlert);
    }

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let handleUserSelectInput = e => {
        e.preventDefault();
        const optionObj = e.target.options[e.target.selectedIndex];
        const {name, value,} = e.target;
        let formData = state.formData;
        formData[name] = value;
        formData['last_reading'] = optionObj.getAttribute('data-lastreading') || '';
        formData['date_from'] = optionObj.getAttribute('data-lastreadingdate') || '';
        alterState('formData', formData)
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        APIservices.submitSubMeterReading(state.formData).then((response) => {
            let showAlert = state.showAlert;
            showAlert['show'] = response.data.status
            if (response.data.status === true) {
                showAlert['isError'] = false;
                alterState({isLoading: false, showAlert: showAlert});
            } else {
                showAlert['isError'] = true;
                showAlert['message'] = response.data.data.message + " (" + response.data.data.code + ")";
                alterState('showAlert', showAlert)
            }
        });
    }

    useEffect(() => {
        alterState('isLoading', true);
        const promiseArray = [
            APIservices.getSubMeters(),
            APIservices.getSubMeterReading(state.formData.uuid)
        ];
        Promise.allSettled(promiseArray)
            .then((data) => {
                let showAlert = state.showAlert;
                let formData = state.formData;
                const subMeters = data[0].value;
                const subMeterReading = data[1].value;

                if(subMeterReading.data.status === true) {
                    formData.sub_meter_id = subMeterReading.data.data.sub_meter.uuid;
                    formData.last_reading = subMeterReading.data.data.last_reading;
                    formData.current_reading = subMeterReading.data.data.current_reading;
                    formData.amount = subMeterReading.data.data.amount;
                    formData.date_from = subMeterReading.data.data.date_from;
                    formData.date_to = subMeterReading.data.data.date_to;
                } else {
                    showAlert['isError'] = true;
                    showAlert['message'] = subMeterReading.data.data.message + " (" + subMeterReading.data.data.code + ")";
                }

                alterState({
                    sub_meters: subMeters.data.status === true ? subMeters.data.data : [],
                    isLoading: false,
                    showAlert: showAlert,
                    formData: formData
                });
            })
            .catch((err) => {
                console.error(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <AlertPopup
                    onConfirm={close}
                    alterAlertState={alterAlertState}
                    show={state.showAlert.show}
                    isError={state.showAlert.isError}
                    message={state.showAlert.message}
                />

                <Form.Group controlId="sub_meter_id">
                    <Form.Label>Sub Meter</Form.Label>
                    <Form.Control
                        name='sub_meter_id'
                        as="select"
                        value={state.formData.sub_meter_id.length === 0 ? 'Select Sub Meter' : state.formData.sub_meter_id}
                        onChange={handleUserSelectInput}
                    >
                        <option>Select</option>
                        {state.sub_meters !== undefined && state.sub_meters.map((sub_meter) => {
                            return (<option key={sub_meter.uuid}
                                            data-lastreading={sub_meter.latest_reading.current_reading}
                                            data-lastreadingdate={sub_meter.latest_reading.date_to}
                                            value={sub_meter.uuid}>{sub_meter.name}</option>)
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="last_reading">
                    <Form.Label>Last Reading</Form.Label>
                    <Form.Control
                        readOnly={true}
                        disabled
                        name='last_reading'
                        type="text"
                        placeholder="Sub Meter Last Reading"
                        defaultValue={state.formData.last_reading}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Group controlId="current_reading">
                    <Form.Label>Current Reading</Form.Label>
                    <Form.Control
                        name='current_reading'
                        type="text"
                        placeholder="Sub Meter Current Reading"
                        value={state.formData.current_reading}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} controlId="date_from">
                        <Form.Label>Date From</Form.Label>
                        <Form.Control
                            disabled
                            readOnly={true}
                            name='date_from'
                            type="date"
                            placeholder="Sub Meter Reading From Date"
                            defaultValue={state.formData.date_from}
                            onChange={handleUserInput}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="date_to">
                        <Form.Label>Date To</Form.Label>
                        <Form.Control
                            name='date_to'
                            type="date"
                            placeholder="Sub Meter Reading To Date"
                            value={state.formData.date_to}
                            onChange={handleUserInput}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="account_id">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                        name='amount'
                        type="text"
                        placeholder="Amount"
                        value={state.formData.amount}
                        onChange={handleUserInput}
                    />
                </Form.Group>

                <Button onClick={submitForm} variant="primary">Update</Button>
            </div>
        );
    }
}